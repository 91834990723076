import { InsertLink, LinkedIn, ShareTwoTone } from '@mui/icons-material';
import { Button, Menu, MenuItem, TYPOGRAPHY } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC, Fragment, MouseEvent, useState } from 'react';

import { usePublicAppContext } from '_core/context/public-app-context';
import { useTracker } from '_core/hooks/use-tracker';

import { workDetailModalConfig } from '../config';

type WorkShareButtonProps = {
  agencySlug: string;
  workSlug: string;
};

export const WorkShareButton: FC<WorkShareButtonProps> = ({ agencySlug, workSlug }) => {
  const { t } = useTranslation(workDetailModalConfig.i18nNamespaces);
  const { trackUntyped } = useTracker();
  const { showSnackbar } = usePublicAppContext();

  const [shareMenu, setShareMenu] = useState<null | HTMLElement>(null);

  const trackShare = (target: string) => {
    trackUntyped('profileSharingStarted', {
      agencySlug: agencySlug,
      workSlug: workSlug,
      page: 'dedicated-page',
      element: 'work',
      target,
    });
  };

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setShareMenu(event.currentTarget);
  };

  const handleClose = () => {
    setShareMenu(null);
  };

  return (
    <Fragment>
      <Button
        label={t('portfolio:work.share')}
        buttonStyle="secondary"
        buttonVariant="light"
        size="xs"
        id="share-work-menu-btn"
        aria-label="share-work-menu-btn"
        iconRight={<ShareTwoTone style={{ fontSize: TYPOGRAPHY.SMALL }} />}
        onClick={handleMenuClick}
      />
      <Menu
        anchorEl={shareMenu}
        id="share-work-menu"
        onClose={handleClose}
        open={shareMenu?.id === 'share-work-menu-btn'}>
        <MenuItem
          iconLeft={<LinkedIn style={{ fontSize: TYPOGRAPHY.SMALL }} className="text-secondary-500" />}
          label={t('portfolio:work.linkedin')}
          onClick={() => {
            trackShare('linkedin');
            window.open(
              `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&utm_source=linkedin&utm_campaign=work-sharing&utm_medium=${agencySlug}`,
              '_blank',
            );
          }}
        />
        <MenuItem
          iconLeft={<InsertLink style={{ fontSize: TYPOGRAPHY.SMALL }} className="text-secondary-500" />}
          label={t('portfolio:work.copy')}
          onClick={() => {
            trackShare('copy-link');
            navigator.clipboard.writeText(window.location.toString()).then(
              () => showSnackbar({ type: 'dark', message: t('portfolio:work.copySuccess') }),
              () => showSnackbar({ type: 'error', message: t('portfolio:work.copyError') }),
            );
          }}
        />
      </Menu>
    </Fragment>
  );
};
