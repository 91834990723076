import { isNonEmptyArray } from '@sortlist-frontend/utils';

import { AlternateLinksProps } from './types';
import { getLinks } from './utils';

export const AlternateLinks = (props: AlternateLinksProps) => {
  const links = getLinks(props);

  if (props.query.includes('page=')) return null;

  return (
    isNonEmptyArray(links) &&
    links.map(({ key, href, hreflang }) => {
      return <link key={key} rel="alternate" href={href} hrefLang={hreflang} />;
    })
  );
};
